import React, { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import styles from "../ContractManagement.module.css";
import { Fieldset } from "primereact/fieldset";
import { Link, useParams } from "react-router-dom";
import { contractService } from "src/api/services/contract";
import {
  IContract,
  IAvailableActionDropDown,
  IContactStatusModalState,
  IAvailableAction,
} from "src/api/types/contracts";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import ButtonDropDown from "src/components/Kit/ButtonDropDown";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import ContractStatusModal from "./UpdateContractStatusModal";
import ContractChat from "../ContractChat";
import ContractStatusHistory from "../ContractStatusHistory";
import { getItems, isJobContract } from "./helper";
import UploadReciptImageModal from "./UploadReciptImageModal";
import ReceiptCard from "./ReceiptCard";
import { Box } from "@mui/material";

const ContractDetails: React.FC = () => {
  const routeParams = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [contractDetails, setContractDetails] = useState<IContract>();
  const [availableStatusOptions, setAvailableStatusOptions] = useState<
    IAvailableActionDropDown[]
  >([]);
  const [statusModal, setStatusModal] = useState<IContactStatusModalState>({
    show: false,
    data: undefined,
  });

  const fetchContractDetails = useCallback(async () => {
    if (routeParams.id) {
      try {
        Promise.all([
          contractService.getDetails(routeParams.id),
          contractService.getContractAvailableActions(+routeParams.id),
        ])
          .then((responses) => {
            setContractDetails(responses[0].data.payload);
            updateAvailableStatusOptions(responses[1].data.payload.actions);
          })
          .catch((error) => {
            throw new Error(error);
          });
        setLoading(true);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  }, [routeParams.id]);

  const handleChangeContactStatus = (e: IAvailableActionDropDown) => {
    if (e.key === "undefined") {
      //**** In this case there is no need for further actions
      confirmDialog({
        message: `Are you sure you want to ${e.title}`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: async () => {
          const data = {
            action: e.title,
          };

          try {
            let contractDetailResponse = await contractService.changeState(
              +routeParams.id!,
              data,
            );
            let nextActions = await contractService.getContractAvailableActions(
              +routeParams.id!,
            );

            setContractDetails(contractDetailResponse.data.payload);
            updateAvailableStatusOptions(nextActions.data.payload.actions);
          } catch (err) {
            console.log(err);
          }
        },
      });
    } else {
      //**** Gathering specific details from admin
      setStatusModal({ show: true, data: e });
    }
  };
  const updateAvailableStatusOptions = (options: IAvailableAction[]) => {
    const tempActions = options.map((obj) => ({
      title: obj.name,
      key: obj?.commentTitle || "undefined",
    }));

    setAvailableStatusOptions(tempActions);
  };

  useEffect(() => {
    fetchContractDetails();
  }, [fetchContractDetails]);

  return (
    <div>
      {loading && <div>Loading...</div>}
      <div className="mb-3">
        <Fieldset legend="Contract Info">
          <div className="grid mb-3">
            <div className="col">
              <b className={`mr-2`}>Freelancer:</b>
              {!!contractDetails && (
                <Link
                  to={
                    ROUTE_CONSTANTS.DASHBOARD.FREELANCERS.DETAILS.GET_BY_DATA(
                      contractDetails?.freelancer.id,
                    ).ABSOLUTE
                  }>{`${contractDetails.freelancer.firstName} ${contractDetails.freelancer.lastName}`}</Link>
              )}
            </div>
            <div className="col">
              <b className="mr-2">Customer:</b>
              {!!contractDetails && (
                <Link
                  to={
                    ROUTE_CONSTANTS.DASHBOARD.CUSTOMERS.DETAILS.GET_BY_DATA(
                      contractDetails?.customer.id,
                    ).ABSOLUTE
                  }>{`${contractDetails.customer.firstName} ${contractDetails.customer.lastName}`}</Link>
              )}
            </div>
          </div>
          <div className="grid">
            <div className="col">
              <span className={styles["field-slug"]}> Open Date: </span>
              <span className="ml-2 font-medium">
                {dayjs(contractDetails?.openDate).format("YYYY-MM-DD HH:mm:ss")}
              </span>
            </div>
            <div className="col">
              <span className={styles["field-slug"]}>Due Date: </span>
              <span className="ml-2 font-medium">
                {dayjs(contractDetails?.dueDate).format("YYYY-MM-DD HH:mm:ss")}
              </span>
            </div>
          </div>
          <div className="grid mt-3">
            <div className="col">
              <span className={styles["field-slug"]}>Due Date Status: </span>
              <span className="ml-2 font-medium">
                {contractDetails?.dueDateStatus}
              </span>
            </div>
            <div className="col">
              <span className={styles["field-slug"]}>status:</span>
              <span className="ml-2 font-medium">
                {contractDetails?.status}
              </span>
            </div>
          </div>
          <div className="grid mt-3">
            <div className="col">
              <span className={styles["field-slug"]}>Created At:</span>
              <span className="ml-2 font-medium">
                {" "}
                {dayjs(contractDetails?.createdAt).format(
                  "YYYY-MM-DD HH:mm:ss",
                )}
              </span>
            </div>
            <div className="col">
              <span className={styles["field-slug"]}>Fee:</span>
              <span className="ml-2 font-medium">
                {contractDetails?.fee} KD
              </span>
            </div>
          </div>
          {contractDetails?.[0]?.url ? (
            <div className="grid mt-3">
              <div className="col">
                <span className={styles["field-slug"]}>Invoice Image:</span>
                <Box sx={{ pt: 2 }}>
                  <ReceiptCard imageUrl={contractDetails?.[0]?.url} />
                </Box>
              </div>
            </div>
          ) : null}

          {availableStatusOptions.length > 0 && ( //**** For checking if admin has any option to change or not
            <div className="grid mt-3">
              <div className="col">
                <ButtonDropDown
                  label="Update contract status"
                  items={availableStatusOptions}
                  onChange={handleChangeContactStatus}
                />
              </div>
            </div>
          )}
        </Fieldset>
      </div>
      <div className="mb-3">
        <ContractStatusHistory />
      </div>
      <div className="mb-3">
        <Fieldset
          legend={isJobContract(contractDetails) ? "Job Info" : "Package Info"}>
          <div className="mt-3">
            <span className={styles["field-slug"]}>Title:</span>
            <span className="ml-2 font-medium">
              {contractDetails?.package?.title || contractDetails?.job?.title}
            </span>
          </div>
          <div className="mt-3">
            <span className={styles["field-slug"]}>Description:</span>
            <span className={`${styles.description} ml-2 font-medium`}>
              {contractDetails?.package?.description ||
                contractDetails?.job?.description}
            </span>
          </div>
          <div className="mt-3">
            <span className={styles["field-slug"]}>Price:</span>
            <span className="ml-2 font-medium">
              {contractDetails?.package?.price ||
                contractDetails?.package?.price}{" "}
              KD
            </span>
          </div>
          <div className="mt-3">
            <div className="mb-2">
              <b>Items:</b>
            </div>
            <ul>
              {getItems(contractDetails).map((item, ind) => (
                <li key={`${item}_${ind}`} className="mb-2 font-light">
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </Fieldset>
      </div>
      <div className="mb-3">
        <Fieldset legend="Financial Info">
          <div className="mt-3">
            <span className={styles["field-slug"]}>Status:</span>
            <span className="ml-2 font-medium">
              {contractDetails?.financialDetail?.status ?? "-"}
            </span>
          </div>
          <div className="mt-3">
            <span className={styles["field-slug"]}>Price:</span>
            <span className="ml-2 font-medium">
              {(contractDetails?.package?.price ||
                contractDetails?.job?.price) ??
                "-"}{" "}
              KD
            </span>
          </div>
          {/* <div className="mt-3">
                    <span className={styles["field-slug"]}>Discount Detail:</span>
                    <span className="ml-2 font-medium">{contractDetails?.discountDetail ?? '-'}</span>
                </div> */}
          <div className="mt-3">
            <span className={styles["field-slug"]}>Discount Amount:</span>
            <span className="ml-2 font-medium">
              {contractDetails?.discountDetail ?? "-"}
            </span>
          </div>
          <div className="mt-3">
            <span className={styles["field-slug"]}>Total:</span>
            <span className="ml-2 font-medium">
              {contractDetails?.price ?? "-"} KD
            </span>
          </div>
          <div className="mt-3">
            <span className={styles["field-slug"]}>Record ID:</span>
            <span className="ml-2 font-medium">
              {contractDetails?.financialDetail?.recordId ?? "-"}
            </span>
          </div>
          <div className="mt-3">
            <span className={styles["field-slug"]}>Date:</span>
            <span className="ml-2 font-medium">
              {dayjs(contractDetails?.financialDetail?.date).format(
                "YYYY-MM-DD HH:mm:ss",
              )}
            </span>
          </div>
        </Fieldset>
        <ContractChat />
      </div>
      <ConfirmDialog />
      <ContractStatusModal
        show={statusModal.show && statusModal.data?.title !== "TransferMoney"}
        data={statusModal.data!}
        contractId={+routeParams.id!}
        onHide={() => setStatusModal({ show: false, data: undefined })}
        updateStateActions={(actionsResponse: IAvailableAction[]) =>
          updateAvailableStatusOptions(actionsResponse)
        }
        updateContractDetail={(contractDetails: IContract) =>
          setContractDetails(contractDetails)
        }
      />
      <UploadReciptImageModal
        show={statusModal.show && statusModal.data?.title === "TransferMoney"}
        data={statusModal.data!}
        contractId={+routeParams.id!}
        onHide={() => setStatusModal({ show: false, data: undefined })}
        updateStateActions={(actionsResponse: IAvailableAction[]) =>
          updateAvailableStatusOptions(actionsResponse)
        }
        updateContractDetail={(contractDetails: IContract) =>
          setContractDetails(contractDetails)
        }
      />
    </div>
  );
};

export default ContractDetails;
